[data-theme="default"],
:root {
  /* constants */
  --border-radius: 8px;
  --nav-height: 60px;
  --transition: all 0.25s cubic-bezier(0.645,0.045,0.355,1);

  /* color scheme */
  --colors-a-tag-hover: #8ca98b;
  --colors-a-tag: #c4d6c3;
  --colors-background: #2e2b26;
  --colors-font-inverse: #1b1a17;
  --colors-font: #f5f5f5;
  --colors-neutral: #e5dfce;
  --colors-on-neutral: var(--colors-font-inverse);
  --colors-primary: #fcba74;
  --colors-secondary-surface: #585343;
  --colors-shadow: #1b1a17;
  --colors-surface: #1b1a17;

  --project-image-transition: all cubic-bezier(.4,0,.2,1) .4s;

  /* spacing */
  --spacing-small: 10px;
  --spacing-medium: 16px;
  --spacing-large: 40px;

  /* size */
  --size-mobileS: 320px;
  --size-mobileM: 375px;
  --size-mobileL: 425px;
  --size-tablet: 768px;
  --size-laptop: 1024px;
  --size-laptopL: 1440px;
  --size-desktop: 2560px;
  
  /* device */
  --device-mobileS: max-width: var(--size-mobileS);
  --device-mobileM: max-width: var(--size-mobileM);
  --device-mobileL: max-width: var(--size-mobileL);
  --device-tablet: max-width: var(--size-tablet);
  --device-laptop: max-width: var(--size-laptop);
  --device-laptopL: max-width: var(--size-laptopL);
  --device-desktop: max-width: var(--size-desktop);
  --device-desktopL: max-width: var(--size-desktopL);
}

html {
  scroll-behavior: smooth;
  font-size: 16px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--colors-background);
  color: var(--colors-font);
}

section {
  max-width: 1000px;
}

a:hover {
  color: var(--colors-a-tag-hover);
  text-decoration: none;
}

a {
  color:var(--colors-a-tag);
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h3 {
  margin-top: 0;
  margin-bottom: 0;
}

.home-section {
  padding: 80px 0;
}